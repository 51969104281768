.about-content {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 34px;

    /* or 64% */
    letter-spacing: 2px;
    text-transform: capitalize;
    color: #000000;
    margin-left: 13%;
    width: 54%;
}

.lawyer-photo   {
    margin-top: 6%;
}

