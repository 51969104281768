.term-title, .terms-content {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 23px;

    /* or 64% */
    letter-spacing: 1px;
    text-transform: capitalize;
    color: #000000;
}

.term-title {
    margin-top: 8%;
}

.modal-content    {
    background: #FFFFFF;
    border-radius: 30px !important;
}


.container > .term-title {
    margin-bottom: 5%;
}

.container > .terms-content ~ .terms-content {
    margin-top: 0px;
}

.terms-content{
    font-size: 21px;
}

.modal--active .content {
    -webkit-filter: blur(8px);
            filter: blur(8px);
    opacity: 1;
}

.button-i-agree {
    background: linear-gradient(180deg, #00FF47 0%, #00B612 100%);
    box-shadow: 0px 0px 4px 5px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 36px;

    /* identical to box height, or 164% */
    letter-spacing: 0.5px;

    /* Neutral / Grey 1 */
    color: #F9F8F9;

    
}
  