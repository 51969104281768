.text   {
    text-align: center;
    height: 97px;

    font-family: Playfair Display;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 120%;

    /* or 43px */
    letter-spacing: 0.02em;

    color: #000000;

    margin-top: 25%;
    margin-bottom: 30px;
}

hr { 
    width: 300px;
    margin: 3.5% auto !important;
    border: 0.5px solid #000000;
    opacity: unset;
}


.count-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.3px;
    font-feature-settings: 'salt' on, 'liga' off;
    color: #000000;
}



.name   {
    height: 43px;
    font-family: Poppins;
    font-style: normal;
    font-size: 48px;
    line-height: 23px;

    align-items: center;
    letter-spacing: 1.5px;

    color: #000000;

    mix-blend-mode: normal;
    margin-bottom: 21px;

    text-align: center;

    margin-top: 6%;
}

.bold-text  {
    font-family: Playfair Display;
    font-style: normal;
    font-weight: 900;
    font-size: 48px;
    line-height: 64px;

    /* identical to box height, or 133% */
    text-align: center;
    letter-spacing: 0.005em;
    font-feature-settings: 'salt' on, 'liga' off;

    color: #000000;
}

.font-popins {
    font-family: Poppins;
}


/* .count  {
    padding-right: 254px;
    padding-left: 213px;
} */

.small-text {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;

    /* identical to box height, or 178% */
    text-align: center;
    letter-spacing: 0.5px;
    font-feature-settings: 'salt' on, 'liga' off;

    color: #000000;
}

.list-center    {
    list-style-type: none;
    margin: auto;
    padding: 0;
    position: relative;
    left: 5%;
}

.practice-areas {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 54px;

    /* or 225% */
    text-align: justify;
    letter-spacing: 0.01em;
    text-transform: capitalize;
}

.practice-areas-list    {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 54px;

    /* or 225% */
    text-align: justify;
    letter-spacing: 0.01em;
    text-transform: capitalize;

    padding: 2%;

    color: #000000;
}

@media only screen and (min-width:768px) {
    .vl {
        border-left: 1px solid #000000;
    }
}

@media only screen and (max-width:768px) {
    ul  {
        list-style: none;
        text-align: center;
        left: 0% !important;
        padding-left: 0rem !important;
    }
    .list-center {
        list-style-type: none;
        margin: auto;
        padding: 0;
        position: relative;
        left: 0%;
        margin-bottom: -16px;
    }

    .mobile-font    {
        font-size: 44px;
    }
}
