.contact-details-font   {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;

    /* or 128% */
    display: block;
    align-items: center;
    text-align: center;
    letter-spacing: 0.5px;
    text-decoration-line: underline;

    color: #000000;

    mix-blend-mode: normal;
    word-break: break-word;
}

#address    {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;

    /* or 115% */
    text-align: center;
    letter-spacing: 1.5px;

    color: #000000;

    mix-blend-mode: normal;
}



#phone  {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;

    /* identical to box height, or 115% */
    text-align: center;
    letter-spacing: 1.5px;

    color: #000000;

    mix-blend-mode: normal;
}

@media only screen and (min-width:768px) {
    .border-left-line {
        border-left: 1px solid #000000;
    }

    .border-right-line {
        border-right: 1px solid #000000;
    }

    #location-direction {
        margin-top: 2%;
    }

    .contact-box {
        padding: 3%;
    }
}

@media only screen and (max-width:767px) {
    .border-line {
        border-top: 1px solid #000000;
    }

    .contact-box {
        padding: 7%;
    }
}