.testimonials    {
    background-color: #000000;
    min-height: 85vh;
}

.textimonal-text    {
    font-family: Playfair Display;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 44px;

    /* identical to box height, or 92% */
    letter-spacing: 1.5px;

    color: #FFFFFF;
    padding-left: 6%;
}


  .box{
    width: 98%;
    height: 69%;
    margin-left: 4%;
    margin-bottom: 6%;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 5%;
    overflow-y: scroll;
  }

  .box::after {
    content: '';
    position: absolute;
    top: 68.5%;
    left: 50%;
    margin-left: -38px;
    width: 0;
    height: 0;
    border-top: solid 27px #fff;
    border-left: solid 49px transparent;
    transform-origin: 0 0;
    border-right: solid 50px transparent;
  }
  

  .client-photo {
    margin-right: 2%;
    position: relative;
    width: 68px;
    height: 68px;
    overflow: hidden;
    object-fit: cover;
  }

  .client-name  {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;

    /* identical to box height, or 156% */
    font-feature-settings: 'salt' on, 'liga' off;

    color: #FAFAFA;
  }

  .scrolling-wrapper-flexbox  {
    display: flex;
    flex-wrap: nowrap !important;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-bottom: 2%;
  }