.index  {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;

    /* or 115% */
    letter-spacing: 1.5px;

    color: #FAFAFA;

    mix-blend-mode: normal;
}


.lawyer-logo {
    width: 36px;
    height: 36px;
    margin-left: 4%;
    margin-right: 1%;
}


.navbar-dark .navbar-nav .active>.nav-link  {
    color: #FAFF00 !important;
}

.navbar-nav {
    margin-left: auto;
}

.navbar-nav > li{
    margin-right: 60px;
}

.header-options {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;

    /* identical to box height, or 192% */
    letter-spacing: 1.5px;

    color: #FAFAFA;
}