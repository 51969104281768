.call-text   {
    font-family: Playfair Display;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 64px;

    /* or 133% */
    letter-spacing: 0.5px;
    font-feature-settings: 'salt' on, 'liga' off;

    color: #000000;
}

.call-button  {
    background: linear-gradient(180deg, #0D0D0D 0%, #313030 100%);
    box-shadow: 0px 0px 4px 5px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
}

.contact-img    {
    width: 100%;
}